import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>A Heartfelt Thanks to Our Herd - 2023 Recap 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/Thank-you-2023-recap.png"
          alt="Purple Cow Internet - Picking the right plan"
          /> 
            <p>Dear Herd Members,</p>
            <p>As the year draws to a close, we at Purple Cow Internet find ourselves reflecting on the incredible journey we've embarked upon, made possible only through the unwavering support of each one of you - our cherished herd.</p>
            <p>Our mission at Purple Cow Internet has always been clear and steadfast: to drive down the overall cost of internet in the Maritimes. We believe that reliable internet should not be a luxury but a fundamental service, offered at an amazing price. This year, we've grown by an astounding 30%, marking our place as one of the fastest-growing internet service providers in Canada. Ring the cow bells!!!</p>
            <p>This remarkable achievement is a direct testament to the loyalty and understanding you, our herd members, have shown!!</p>
            <p>Your choice to join and support the herd sends a resounding message to the larger ISPs - that overcharging is not justifiable and change is imperative. This year has been a milestone for us, not only in terms of growth but also in expanding our offerings. We proudly launched <a href="https://purplecowinternet.com/tv/">Purple Cow TV</a> and <a href="https://purplecowinternet.com/home-phone/">Home Phone</a> services, rounding out our portfolio to meet more of your needs. Oh did I mention we also expanded our services to <a href="https://purplecowinternet.com/thoughts/Purple-Cow-Internet-Expands-its-Services-to-Prince-Edward-Island-and-Newfoundland/">PEI and NFLD</a>!!! </p>  
            <p>Admittedly, with rapid growth and launching two new services come challenges. We faced a few, particularly in building and training our team quickly enough to keep pace. We acknowledge that our customer service response times during July, August, and September were not up to the mark, at times extending to 40-minute phone hold times. We are thrilled to report that we have completely corrected this and now have an average hold time under 3 minutes and 80% of calls are being connected to a team member immediately. Plus, our new call-back feature means no more waiting on hold if you choose.</p>
            <p>The new year looks promising with many exciting developments in the pipeline. We are eager to share these with you soon, as we believe they will revolutionarily change the internet landscape in Nova Scotia and further our mission of making internet services more affordable in the Maritimes.</p>
            <p>As we step into the holiday season, we want to extend our deepest gratitude for your continued support and belief in our vision. Here's to a fantastic holiday season and an even more remarkable new year!</p>
            <p>Thank you for being a part of the herd. Your support fuels our drive to innovate and improve, every single day.</p>
            <p>Warmest regards,</p>
            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
